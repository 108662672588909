<style>
  @import "/css/story.css";
</style>

<template>
    <div class="services">
        <Sport></Sport>
    </div>
</template>

<script>
import Sport from '@/components/Services/Sport.vue'

export default {
    name: 'Y-sport',

    components: {
        Sport
    }
}
</script>
